import { types } from "../types/types";


const initialState = { 
    feedback: {
        messages: [] // Inicializa como un array vacío
    }
};

export const ChatReducer = ( state = initialState, action ) => {  
    switch( action.type ) {      
        case types.chatFeedbackUser:
            return {
                ...state,
                feedback: {...action.payload}
            }
        case types.chatSendFeedback:
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    messages: [
                        ...state.feedback.messages,
                        action.payload
                    ]
                } 
            }  
        
        case types.chatReadFeedback:
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    messages: state.feedback.messages.map(message =>
                        message.sender === action.payload 
                        ? {...message, isRead: true } 
                        : message
                    )
                } 
                    // El map devuelve un nuevo array con los cambios,
                    // dejando el original intacto.
            }
        default:
            return state;
        }
}