import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";


export const getAllClaims = () => { 
    return async( dispatch ) => { 

        try {
            
            const resp = await fetchConToken(`claims/`);
            const body = await resp.json();            
            if( body.ok) {
                dispatch( getAllClaimsStore(body.claims) );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: body.error,
                    icon: 'error',
                });
            }

        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al obtener los productos`,
                icon: 'error',
            })
        }

    }
}

export const addClaims = (reclamo) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`claims`, reclamo, 'POST');            
            if (!resp.ok) {
                throw new Error(`Error en la solicitud: ${resp.status} ${resp.statusText}`);
            }

            const body = await resp.json();

            if (body.ok) {
                Swal.fire({
                    title: 'Reclamo Realizado',
                    text: 'Reclamo enviado correctamente!',
                    icon: 'success',
                });
            } else {
                throw new Error(body.msg || 'Error desconocido al enviar el reclamo');
            }
        } catch (error) {
            console.error('Error al agregar el reclamo:', error);
            Swal.fire({
                title: 'Error',
                text: error.message || 'No se pudo realizar el reclamo. Intente nuevamente más tarde.',
                icon: 'error',
            });
        }
    };
};

export const markReclamoAsRead = (id) => {
    return async (dispatch) => { 
        try {
            const resp = await fetchConToken(`claims/${id}/read`, {}, 'PUT');            
            if (!resp.ok) {
                throw new Error(`Error en la solicitud: ${resp.status} ${resp.statusText}`);
            }

            const body = await resp.json();

            if (body.ok) {
                dispatch(markReclamoAsReadStore(body.claim));
            } else {
                throw new Error(body.msg || 'Error desconocido al enviar el reclamo');
            }
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message || 'No se pudo realizar el reclamo. Intente nuevamente más tarde.',
                icon: 'error',
            });
        }
    }
};

export const getAllClaimsStore = ( claims ) => { 
    return {
        type: types.claimsGetAll,
        payload: {
            claims
        }
    }
}

export const markReclamoAsReadStore = ( claim ) => {    
    return {
        type: types.claimUpdatedRead,
        payload: claim.id
    }
}