import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { getFeedback, sendFeedback, updateRead } from "../../actions/chatActions";
import { useLocation } from "react-router-dom";
// import { sendMessage } from "../actions/chatActions";

export const ComunicacionScreen = () => {
    const { search } = useLocation();
    const userId = new URLSearchParams(search).get("userId");
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth); // Usuario autenticado
    const { feedback } = useSelector((state) => state.chat); // Mensajes y estado de carga
    const [messageContent, setMessageContent] = useState("");
    const chatEndRef = useRef(null); // Referencia al final del chat

    const { isLoading } = useSelector((state ) => state.ui );

    useEffect(() => {
        if (! userId ) {
            dispatch(getFeedback(user.id)); // Cargar mensajes del chat
        } else {
            dispatch(getFeedback(userId)); // Cargar mensajes del chat
        }
        if( feedback?.id  ) {
            dispatch(updateRead( feedback.id, (user.role === 'admin') ? 'user' : 'admin'));
        }
    }, [dispatch, user, feedback?.id, userId]);    

    useEffect(() => {
        if (feedback?.messages) {
            scrollToBottom();
        }
    }, [feedback?.messages]);

    const handleSendMessage = () => {
        if (messageContent.trim() !== "") {
            dispatch(
                sendFeedback({                    
                    sender: user.role === "admin" ? "admin" : "user", // Identificar remitente
                    content: messageContent,
                    feedbackId: feedback.id
                })
            );
            setMessageContent(""); // Limpiar cuadro de texto
        }
    };


    const scrollToBottom = () => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="d-flex flex-column comunicacion_vh-90">
            {/* Contenedor de mensajes */}
            <div className="flex-grow-1 overflow-auto p-3" style={{ backgroundColor: "#f9f9f9" }}>
                {isLoading ? (
                    <p>Cargando mensajes...</p>
                ) : (
                    feedback?.messages?.map((msg) => (
                        <div
                            key={msg.id}
                            className={`comunicacion_chat-message ${
                                msg.sender !== user.role ? "text-start" : "text-end"
                            }`}
                            style={{
                                margin: "10px 0",
                                textAlign: msg.sender !== user.role ? "left" : "right",
                            }}
                        >
                            <div
                                className={`p-2 rounded ${
                                    msg.sender === "user"
                                        ? "bg-light text-dark"
                                        : "bg-primary text-white"
                                }`}
                                style={{ display: "inline-block", maxWidth: "70%" }}
                            >
                                {msg.content}
                                <small
                                    className="d-block mt-1"
                                    style={{ fontSize: "0.8rem", textAlign: "right" }}
                                >
                                    {new Date(msg.createdAt).toLocaleString()}
                                    {
                                        ( msg.isRead ) 
                                        ? <i className="fas fa-check-double ms-1"></i>
                                        : <i className="fas fa-check ms-1"></i>

                                    }
                                </small>
                            </div>
                        </div>
                    ))
                )}
                {/* Referencia para desplazar el scroll hacia abajo */}
                <div ref={chatEndRef} />
            </div>

            {/* Contenedor del cuadro de texto y botón */}
            <div className="border-top p-3 bg-white">
                <InputGroup>
                    <FormControl
                        as="textarea"
                        rows={2}
                        placeholder="Escribe un mensaje..."
                        aria-label="Mensaje"
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                        style={{ resize: "none" }}
                    />
                    <Button variant="primary" onClick={handleSendMessage}>
                        Enviar
                    </Button>
                </InputGroup>
            </div>
        </div>
    );
};
