import { types } from "../types/types";


const initialState = { 
    claims: [],
};

export const ClaimReducer = ( state = initialState, action ) => {  

    switch( action.type ) {
        case types.claimsGetAll:
            return {
                ...state,
                claims: [ ...action.payload.claims]
            }
        case types.claimUpdatedRead:
            return {
                ...state,
                claims: state.claims.map(claim => 
                    claim.id === action.payload 
                       ? {...claim, isRead: true } 
                        : claim
                )
            }
        case types.claimDeleted:
        default:
            return state;
        }

}