import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";


export const getAllChat = () => { 
    return async( dispatch ) => { 
        try {
            const resp = await fetchConToken('feedback/');
            const body = await resp.json();

            if( body.ok ) {
                dispatch( getAllChatStore(body.feedbacks) );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: body.error,
                    icon: 'error',
                })
            }
        } catch (error) { 
            Swal.fire({
                title: 'Error',
                text: 'Error al recuperar los chat',
                icon: 'error',
            })
        }
    }
}

export const getAllChatStore = ( feedback ) => {
    return {
        type: types.chatAllFeedback,
        payload: feedback
    }
}