import { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addClaims } from "../../actions/reclamoActions";


export const AddReclamosScreen = () => { 
    const [reclamo, setReclamo] = useState('');
    const dispatch = useDispatch();

    const handleReclamoChange = (e) => {
        setReclamo(e.target.value);
    };

    const handleSubmit = () => {
        if (reclamo.trim()) {
            dispatch(addClaims({ message: reclamo }));
            setReclamo(''); // Limpiar el campo después de enviar
        } else {
            alert('Por favor, ingrese un reclamo antes de enviar.');
        }
    };

    return (
        <div className="d-flex flex-column align-items-center mt-5">
            <div className="w-75">
                <InputGroup className="mb-3">
                    <FormControl
                        as="textarea"
                        rows={4}
                        placeholder="Ingrese su reclamo aquí..."
                        aria-label="Reclamo"
                        value={reclamo}
                        onChange={handleReclamoChange}
                    />
                </InputGroup>
                <div className="d-flex justify-content-end">
                    <Button variant="primary" onClick={handleSubmit}>
                        Enviar Reclamo
                    </Button>
                </div>
            </div>
        </div>
    );
}