
import React, { useEffect, useState } from 'react';
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../actions/authActions';
import { UpdateUser } from '../../admin/components/UpdateUser';
import { updateUserSelect } from '../../../actions/usersActions';
import { removeError } from '../../../actions/uiActions';

import { OptionDolar } from './OptionDolar';
import { fetchConToken } from '../../../helpers/fetch';

export const NavBarC = () => {

    const dolarState = useSelector( state => state.dolar);
    const [unreadCount, setUnreadCount] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { isAuth, user } = useSelector( state => state.auth );
    const [showAddUpdate, setShowAddUpdate] = useState(false);
    const [showDolar, setShowDolar] = useState(false);

    useEffect(() => {
        let intervalId;
      
        if (isAuth) {
          fetchUnreadMessages(); // Llama al cargar o al navegar
          intervalId = setInterval(fetchUnreadMessages, 30000); // Actualiza cada 30 segundos
        }
      
        return () => {
          clearInterval(intervalId); // Limpia el intervalo al desmontar
        };
      }, [isAuth, location]);

    const fetchUnreadMessages = async () => {
        try {
          const body = await fetchConToken('message/count')
      
          if (body.ok) {
            const data = await body.json();
            setUnreadCount(data.count); // Actualiza el estado con la cantidad
          } else {
            console.error('Error al obtener los mensajes no leídos');
          }
        } catch (error) {
          console.error('Error al conectar con el servidor:', error);
        }
      };

    const handleLogin = () => { 
        navigate( '/auth/login' );
    }

    const handleRegister = () => {
        navigate('/auth/register');
    }

    const handleLogout = () => { 
        dispatch( logout() );
        navigate('/');
    }

    const handleEditPerfil = () => {
        dispatch( updateUserSelect(user) );
        setShowAddUpdate(true);
    }

    const handleUpdateClose = () => { 
        dispatch( updateUserSelect(null) );
        dispatch( removeError() );
        setShowAddUpdate(false); 
    }

    const onHideDolar = () => {
        setShowDolar(false);
    }

    const handleShowModal = () => {
        setShowDolar(true);
    }


  return (
    <>
        <Navbar bg="light" expand="lg" className="navbar__navbar no-print">
            <Container fluid>
                {/* <Navbar.Brand href="#" className="navbar__title"> */}
                    <Nav.Link as={Link} to="/" className="navbar__title">
                        Fijamom
                    </Nav.Link>
                {/* </Navbar.Brand> */}
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    // navbarScroll
                >
                    {/* <Nav.Link as={Link} to="/productosdestacados" className="navbar__menu">Productos Destacados</Nav.Link> */}
                    <Nav.Link as={Link} to="/productos" className="navbar__menu">Inicio</Nav.Link>
                    {
                        (user?.role === 'admin' || user?.role === 'superadmin') && (
                        <NavDropdown title={
                            <>
                                Administración
                                {unreadCount > 0 && (
                                    <span className="badge bg-danger ms-2">{unreadCount}</span>
                                )}
                            </>
                        } id="navbarScrollingDropdown" className="navbar__menu">
                            <NavDropdown.Item as={Link} to="/productosadmin">Productos</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/stock">Stock</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/categorias">Categorias</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/usuarios">Usuarios</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/pedidos">Pedidos</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/mapapedidos">Mapa de Pedidos</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/estadisticas">Estadísticas</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/reclamos-admin">Reclamos</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={"/comunicaciones"}>
                                Comunicarme
                                {unreadCount > 0 && (
                                    <span className="badge bg-danger ms-2">{unreadCount}</span>
                                )}
                            </NavDropdown.Item>
                        </NavDropdown>)                        
                    }
                    {
                        (user?.role === 'user') && (
                            <NavDropdown title={
                                <>
                                    Comunicación
                                    {unreadCount > 0 && (
                                        <span className="badge bg-danger ms-2">{unreadCount}</span>
                                    )}
                                </>
                            } id="navbarScrollingDropdown" className="navbar__menu">
                                <NavDropdown.Item as={Link} to="/reclamos">Reclamos</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/comunicacion">
                                    Comunicarme
                                    {unreadCount > 0 && (
                                        <span className="badge bg-danger ms-2">{unreadCount}</span>
                                    )}
                                </NavDropdown.Item>
                            </NavDropdown>
                        )
                    }
                </Nav>
                {
                    (isAuth) && (user?.role === 'admin' || user?.role ==='superadmin') && (                        
                        <h6 className='mt-2 me-5 navbar__dolar pointer'
                            onClick={handleShowModal}>Cotización dolar: ${ (dolarState.dolarCustom?.isDolar)? dolarState.dolarCustom?.dolar?.toLocaleString('es-Ar') : dolarState?.dolarNacion?.toLocaleString('es-Ar') }</h6>
                    )
                }
                {
                    (user)?
                    (<h5 className="navbar__header-user-name me-3 mt-md-3 mt-5 pointer"
                         onClick = { handleEditPerfil }
                         >
                            { user.nameMarkets }
                    </h5>)
                    :(
                        <Button variant="primary" className="ms-2 me-3" onClick= { handleRegister }>Registrar</Button>
                    )
                }
                {( isAuth ) 
                        ? (<Button variant="danger" className="ms-2 me-3" onClick={handleLogout}>Salir</Button>)
                        : (<Button variant="primary" className="ms-2 me-3" onClick= { handleLogin }>Acceder</Button>) }
                    
                </Navbar.Collapse>
            </Container>
            </Navbar>

            <UpdateUser show={showAddUpdate} onHide={handleUpdateClose} />
            <OptionDolar show={showDolar} onHide={onHideDolar} />
    </>
  )
}
