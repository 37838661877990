

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from '../reducers/authReducer';
import { userReducer } from '../reducers/userReducer';
import { productReducer } from '../reducers/productReducer';
import { uiReducer } from '../reducers/uiReducers';
import { CartReducer } from '../reducers/cartReducer';
import { OrderReducer } from '../reducers/orderReducer';
import { dolarReducer } from '../reducers/dolarReducer';
import { ClaimReducer } from './../reducers/claimReducer';
import { ChatReducer } from '../reducers/chatReducer';
import { ChatsReducer } from '../reducers/chatsReducer';

const reducers = combineReducers({ 
    auth: authReducer,
    ui: uiReducer,
    users: userReducer,
    products: productReducer,
    carts: CartReducer,
    orders: OrderReducer,
    dolar: dolarReducer,
    claims: ClaimReducer,
    chat: ChatReducer,
    chats: ChatsReducer
});

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
//const composeEnhancers = compose;


export const store = createStore( 
    reducers,
    composeEnhancers( applyMiddleware(thunk) )
);