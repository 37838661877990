import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { ProductosAdmin } from '../pages/admin/ProductosAdmin';
import { UsuarioAdmin } from '../pages/admin/UsuarioAdmin';
import { CategoriasAdmin } from '../pages/admin/CategoriasAdmin';
import { EstadisticasAdmin } from '../pages/admin/EstadisticasAdmin';
import { PedidosAdmin } from '../pages/admin/PedidosAdmin';
import { MapaAdmin } from '../pages/admin/MapaAdmin';
import { OrderDetailsPrint } from '../pages/admin/components/OrdersDetailPrint';
import { ProductPrint } from '../pages/admin/components/ProductPrint';
import { StockAdmin } from '../pages/admin/StockAdmin';
import { AddReclamosScreen } from '../pages/dashboard/AddReclamos';
import { ComunicacionScreen } from '../pages/dashboard/Comunicacion';
import { ReclamosAdmin } from '../pages/admin/ReclamoAdmin';
import { ComunicacionesAdmin } from '../pages/admin/ComunicacionesAdmin';

export const DashboardRoute = () => {
  return (
    <>
        <Routes>
            <Route path="/productosadmin" element={<ProductosAdmin />} />
            <Route path="/usuarios" element={<UsuarioAdmin />} />
            <Route path="/categorias" element={<CategoriasAdmin />} />
            <Route path="/pedidos" element={<PedidosAdmin />} />
            <Route path="/estadisticas" element={<EstadisticasAdmin />} />
            <Route path="/mapapedidos" element={<MapaAdmin />} />
            <Route path="/orders-print" element={<OrderDetailsPrint />} />
            <Route path="/product-print" element={<ProductPrint />} />
            <Route path="/stock" element={<StockAdmin /> } />
            <Route path="/reclamos" element={<AddReclamosScreen />} />
            <Route path="/comunicacion" element={<ComunicacionScreen />} />
            <Route path="/comunicaciones" element={<ComunicacionesAdmin />} />
            <Route path="/reclamos-admin" element={<ReclamosAdmin />} />
        </Routes>
    </>
  )
}
