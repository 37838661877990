import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllChat } from "../../actions/chatsActions";
import { useNavigate } from "react-router-dom";

export const ComunicacionesAdmin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector( state => state.ui);
    const { feedback } = useSelector( state => state.chats );

    useEffect(() => {
        dispatch(getAllChat()); // Cargar los reclamos al montar el componente
    }, [dispatch]);

    const handleGoChat = (id) => () => {
        navigate(`/comunicacion?userId=${ id }`);
    };

    return (
        <>
            <h1 className="usuarios_title">Listado de reclamos</h1>
            <hr/>

            {isLoading ? (
                <p>Cargando reclamos...</p>
            ) : (
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Usuario</th>
                            <th>Ir</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedback?.length > 0 ? (
                            feedback?.map((chat, index) => (
                                <tr key={chat.id}>                                    
                                    <td>{index + 1}</td>
                                    <td>{chat.user.nameMarkets}</td>
                                    <td>
                                    <i className="fas fa-comment fa-2x text-primary position-relative" 
                                        style={{ cursor: "pointer" }}
                                        onClick={handleGoChat(chat.userId)}>
                                            {chat.unreadMessagesCount > 0 && (
                                                <span className="unread-badge">
                                                    {chat.unreadMessagesCount}
                                                </span>
                                            )}
                                        </i>                                     
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No hay chats para mostrar
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}
        </>
    );
}