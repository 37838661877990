import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";


export const getFeedback = ( userId ) => {
    return async( dispatch) => { 
        try {
            const response = await fetchConToken(`feedback/user/${userId}`);
            const body = await response.json();
            if( body.ok) {
                dispatch( getUserChatStore(body.feedback) );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: body.error,
                    icon: 'error',
                });
            }

        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al obtener los mensajes`,
                icon: 'error',
            })
        }
    }
}

export const sendFeedback = ( data ) => { 
    return async( dispatch ) => { 
        try {
            const response = await fetchConToken(`message`, data, 'POST');
            const body = await response.json();            
            if( body.ok) {
                dispatch( sendFeedbackStore(body.message) );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: body.error,
                    icon: 'error',
                });
            }
        } catch (e) { 
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al enviar el feedback ${e}`,
                icon: 'error',
            })
        }
    }
}

export const updateRead = ( feedbackId, sender  ) => { 
    return async( dispatch ) => { 
        try {
            const response = await fetchConToken(`message/${ feedbackId }/isRead/${ sender }`, {}, 'PUT');
            const body = await response.json();            
            if( body.ok) {
                dispatch( updateReadFeedbackStore(sender) );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: body.error,
                    icon: 'error',
                });
            }
        } catch (e) { 
            Swal.fire({
                title: 'Error',
                text: `Hubo un error al enviar el feedback ${e}`,
                icon: 'error',
            })
        }
    }
}

export const getUserChatStore = ( feedback ) => {
    return {
        type: types.chatFeedbackUser,
        payload: feedback
    }
}

export const sendFeedbackStore = ( message ) => { 
    return {
        type: types.chatSendFeedback,
        payload: message
    }
}

export const updateReadFeedbackStore = ( sender ) => { 
    return {
        type: types.chatReadFeedback,
        payload: sender
    }
}