import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, FormControl, InputGroup, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { removeError } from '../../actions/uiActions';
import { deleteUser, getUsersAll, updateUserSelect, updateUserStatus, updateUserStatusAll } from '../../actions/usersActions';
import { UpdateUser } from './components/UpdateUser';
import { UpdateUserPrice } from './components/UpdateUserPrice';
import { UpdateLocation } from './components/UpdateLocation';
import Swal from 'sweetalert2';
import { UserChangePassword } from './components/UserChangePassword';
import { useNavigate } from 'react-router-dom';

export const UsuarioAdmin = () => {

    const navigate = useNavigate();

    const [ usersFilter, setUsersFilter ] = useState([]);
    const [ sortUpName, setSortUpName] = useState(true);
    const [ sortUpStatus, setSortUpStatus] = useState(true);
    const [ sortNumCliente, setSortNumCliente] = useState(true);
    const { isLoading } = useSelector( state => state.ui);

    const dispatch = useDispatch();
    useEffect(() => { 
        dispatch( getUsersAll() );
    }, [dispatch]);

    const {users} = useSelector( state => state.users );

    

    useEffect(() => {
        setUsersFilter( users );
    } , [users]);

    const [showAddUpdate, setShowAddUpdate] = useState(false);
    const [showAddPrice, setShowAddPrice] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [showChangePass, setShowChangePass] = useState(false);

    const handleUpdateClose = () => { 
        dispatch( updateUserSelect(null) );
        dispatch( removeError() );
        setShowAddUpdate(false); 
    }

    const handleUpdatePriceClose = () => { 
        dispatch( updateUserSelect(null) );
        dispatch( removeError() );
        setShowAddPrice(false);
    }

    const handleShowLocationClose = () => { 
        dispatch( updateUserSelect(null) );
        dispatch( removeError() );
        setShowLocation(false);
    }

    const handleChangePassClose = () => { 
        dispatch( updateUserSelect(null) );
        dispatch( removeError() );
        setShowChangePass(false);
    }

    const handleChangePass = ( user ) => {
        dispatch( updateUserSelect(user) );
        setShowChangePass(true);
    }

    const handleActive = async (id, status) => { 
        dispatch( updateUserStatus(id, status) );
    }

    const handleEdit = (user) => {
        dispatch( updateUserSelect(user) );
        setShowAddUpdate(true);
    }

    const handleLocation = (user) => { 
        dispatch( updateUserSelect(user));
        setShowLocation(true);
    }

    const handlePrice = (user) => { 
        dispatch( updateUserSelect(user) );
        setShowAddPrice( true );
    }

    const filterList = ({ target }) => {
        const { value } = target;
        if( value?.length > 0 ) {
            setUsersFilter( users.filter( 
                user => user.nameMarkets.toLowerCase().includes(value.toLowerCase()) || 
                        user.nameContact.toLowerCase().includes(value.toLowerCase()) ||
                        user.email.toLowerCase().includes(value.toLowerCase())
            ) );
        } else {
            setUsersFilter( users );
        }
    }

    // Ordena por el campo tanto nameMarkets o status
    const handleSort = (campo) => {
        if( campo === 'nameMarkets' ) {
            setSortUpName(!sortUpName);
            if( !sortUpName ){
                usersFilter.sort((a, b) => {                    
                    if (a.nameMarkets > b.nameMarkets) {
                        return -1;
                    }
                    return 1            
                });
            } else {
                usersFilter.sort((a, b) => {
                    if (a.nameMarkets < b.nameMarkets) {
                        return -1;
                    }
                    return 1
                });
            }   
        } else if ( campo === 'status' ) {                  
            setSortUpStatus(!sortUpStatus);
            if( !sortUpStatus ){
                usersFilter.sort((a, b) => {                    
                    if (a.status > b.status) {
                        return -1;
                    } 
                    return 1            
                });
            } else {
                usersFilter.sort((a, b) => {
                    if (a.status < b.status) {
                        return -1;
                    } 
                    return 1
                });
            }            
        } else if( campo === 'numCliente') {
            setSortNumCliente(!sortNumCliente);
            if( !sortNumCliente ){
                usersFilter.sort((a, b) => {                    
                    if (a.numCliente > b.numCliente) {
                        return -1;
                    } 
                    return 1            
                });
            } else {
                usersFilter.sort((a, b) => {
                    if (a.numCliente < b.numCliente) {
                        return -1;
                    } 
                    return 1
                });
            }      
        }
    }

    // const handleIsAdmin = (user, isRole) => {
    //     user.role = isRole;
    //     dispatch( updateUser(user.id, user) );
    // }

    const handleDelete = ( user ) => {
        Swal.fire({
            title: `Estas seguro que desea eliminar el usuario ${ user.nameMarkets }?`,
            text: "No podrás revertir esta acción!",
            icon: "warning",
            confirmButtonText: 'Grabar',
            showCancelButton: 'Cancelar',
        }).then( result => {
            if( result.isConfirmed ) {
                dispatch( deleteUser( user.id ));
            }
        })
    }

    const handleActiveAll = ( status ) => {
        dispatch( updateUserStatusAll(status) );
    }

    const handleChatUser = ( id ) => { 
        navigate(`/comunicacion?userId=${ id }`);
    }

  return (
    <>
        <h1 className="usuarios_title">Listado de usuarios</h1>
        <hr/>

        <div className="d-flex justify-content-center">
            <div className='w75'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><i className='fas fa-search'></i></InputGroup.Text>
                    <FormControl
                        placeholder="Buscar...."
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        onChange={ filterList }
                    />
                </InputGroup>
            </div>
        </div>

        <div className="d-flex justify-content-end mb-3 me-5 pe-5 col-md-12">
            <Button className= 'useradmin__botonera-danger me-2 col-md-3' onClick={ !(isLoading) ? () => handleActiveAll(0) : null }>
                Desactivar Users
            </Button>
            <Button className= 'useradmin__botonera col-md-3' onClick={ !(isLoading) ? () => handleActiveAll(1) : null }>
                Activar Users
            </Button>
        </div>

        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>
                        Nº Cliente
                        {
                            ( sortNumCliente )
                            ? <i className='fas fa-arrow-up ms-2 pointer text-end' onClick={ () => handleSort('numCliente') }></i>
                            : <i className='fas fa-arrow-down ms-2 pointer text-end' onClick={ () => handleSort('numCliente') }></i>
                        }
                    </th>
                    <th>
                        Negocio
                        {
                            ( sortUpName )
                            ? <i className='fas fa-arrow-up ms-2 pointer text-end' onClick={ () => handleSort('nameMarkets') }></i>
                            : <i className='fas fa-arrow-down ms-2 pointer text-end' onClick={ () => handleSort('nameMarkets') }></i>
                        }   
                    </th>
                    <th>Contacto</th>
                    <th>Email</th>
                    <th>L. Precio</th>
                    <th>
                        Estado
                        {
                            ( sortUpStatus )
                            ? <i className='fas fa-arrow-up ms-2 pointer text-end' onClick={ () => handleSort('status') }></i>
                            : <i className='fas fa-arrow-down ms-2 pointer text-end' onClick={ () => handleSort('status') }></i>
                        }     
                    </th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                { 
                    (usersFilter?.length > 0) 
                        ? usersFilter.map( (u, i) => 
                        (<tr key={i}>
                            <td>{u.numCliente}</td>
                            <td>{ u.nameMarkets }</td>
                            <td>{ u.nameContact}</td>
                            <td>{ u.email }</td>
                            <td>
                                {  
                                    `${((u.listaDefault) || 4)}`
                                }    
                            </td>
                            <td>{ 
                                (u.role !== "admin")
                                    ? (
                                        (u.status) 
                                            ? <span className="badge bg-success pointer" onClick={ () => handleActive( u.id, false )}>Activo</span>
                                            : <span className="badge bg-danger pointer" onClick={ () => handleActive( u.id, true )}>Inactivo</span>
                                    ): ''
                                }
                            </td>
                            <td>
                                <div 
                                    className="components__boton-action components__boton-action-edit"
                                    onClick = { () => handleEdit( u )}>
                                    <i className="fas fa-pen text-inverse"></i>
                                </div>
                                <div 
                                    className="components__boton-action components__boton-action-show"
                                    onClick = { () => handleLocation( u )}>
                                    <i className="fa-solid fa-location-pin"></i>
                                </div>
                                <div 
                                    className="components__boton-action components__boton-action-show"
                                    onClick = { () => handlePrice( u )}>
                                    <i className="fa-solid fa-hand-holding-dollar"></i>
                                </div>
                                <div 
                                    className="components__boton-action components__boton-action-delete"
                                    onClick = { () => handleDelete( u )}>
                                    <i className="fas fa-times"></i>
                                </div>
                                <div 
                                    className="components__boton-action components__boton-action-show"
                                    onClick = { () => handleChangePass( u )}>
                                    <i className="fa-solid fa-shield-halved auth__input-icon"></i>
                                </div>
                                <div 
                                    className="components__boton-action components__boton-action-show"
                                    onClick = { () => handleChatUser( u.id )}>
                                    <i className="fas fa-comment auth__input-icon"></i>
                                </div>
                                {/* <div>
                                    {
                                        (u.role === "admin" || u.role === "superadmin") 
                                        ? 
                                        <Button className='ms-5 d-inline-flex'
                                                onClick = { () => handleIsAdmin( u, 'user' )}>Hacer Usuario</Button>
                                        : 
                                        <Button className='ms-5 d-inline-flex'
                                                onClick = { () => handleIsAdmin( u, 'admin' )}>Hacer Admin</Button>
                                    }
                                </div> */}
                            </td>    
                        </tr>))
                        : (<></>)
                }
            </tbody>
        </Table>
        <UpdateUser show={showAddUpdate} onHide={handleUpdateClose} />
        <UserChangePassword show={ showChangePass } onHide={ handleChangePassClose } />
        <UpdateUserPrice show={showAddPrice} onHide={handleUpdatePriceClose} />
        {
        ( showLocation )
            ? <UpdateLocation show={showLocation} onHide={handleShowLocationClose}/>
            : null 
        }
    </>
  )
}
