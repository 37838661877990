import { types } from "../types/types";

const initialState = { 
    feedback: []
};

export const ChatsReducer = ( state = initialState, action ) => {      
    switch( action.type ) {      
        case types.chatAllFeedback: 
            return {
                ...state,
                feedback: [...action.payload]
            }
        default:
            return state;
    }
 
}