import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllClaims, markReclamoAsRead } from "../../actions/reclamoActions";
import { Table } from "react-bootstrap";

export const ReclamosAdmin = () => {

    const dispatch = useDispatch();
    const { isLoading } = useSelector( state => state.ui);
    const { claims } = useSelector( state => state.claims );

    useEffect(() => {
        dispatch(getAllClaims()); // Cargar los reclamos al montar el componente
    }, [dispatch]);

    const handleCheck = (id) => {
        dispatch(markReclamoAsRead(id)); // Acción para marcar el reclamo como leído
    };

    return (
        <>
            <h1 className="usuarios_title">Listado de reclamos</h1>
            <hr/>

            {isLoading ? (
                <p>Cargando reclamos...</p>
            ) : (
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Usuario</th>
                            <th>Fecha</th>
                            <th>Comentario</th>
                            <th>Leído</th>
                        </tr>
                    </thead>
                    <tbody>
                        {claims.length > 0 ? (
                            claims.map((reclamo, index) => (
                                <tr key={reclamo.id}>                                    
                                    <td>{index + 1}</td>
                                    <td>{reclamo.user.nameMarkets}</td>
                                    <td>{new Date(reclamo.createdAt).toLocaleDateString()}</td>
                                    <td>{reclamo.message}</td>
                                    <td>
                                        {
                                             reclamo.isRead? (
                                                 <i className="fas fa-check fa-2x" style={{ color: "green", cursor: "pointer" }}></i>
                                             ) : (
                                                 <i className="fas fa-xmark fa-2x" style={{ color: "red", cursor: "pointer" }} onClick={ () => handleCheck( reclamo.id ) }></i>
                                             )
                                        }                                        
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No hay reclamos para mostrar
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}
        </>
    );
}